<template>
  <v-app>
    <nab-bar class="navbar-sorcero" v-if="navbarApplies"></nab-bar>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script language="JavaScript">
import NabBar from '@/modules/common/components/NavBar.vue'

export default {
  name: 'App',
  components: { NabBar },
  computed: {
    navbarApplies() {
      return this.$route.meta.showNavbar == undefined
    }
  },
}
</script>

<style lang="scss">
  .navbar-sorcero {
    filter: drop-shadow(0px 2px 4px #1515150D);
    background-color: white !important;
  }
</style>
