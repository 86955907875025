import { en } from 'vuetify/lib/locale'

export default {
  ...en,

  common: {
    labels: {
      optional: "Optional",
      accept: "Accept",
      termsAndConditions: "Terms and Conditions",
      project: "Project",
      thankYou: "Thank You!",
      selectAll: "Select all"
    },
    buttons: {
      upload: "Upload",
      create: "Create",
      next: "Next",
      finish: "Finish",
      previousStep: "Previous Step",
      getStarted: "Get Started",
      apply: "Apply",
      clearAll: "Clear All",
      expandAll: "Expand All",
      collapseAll: "Collapse All",
      clearAllStars: "Clear stars"
    }
  },
  projects: {
    labels: {
      myProject: "My Projects",
      newProject: "New Project",
      intelligentLiteratureMonitoring: "Intelligent Literature Monitoring",
      banner: {
        content: "Content",
        areasOfInterest: "Areas of Interest",
        preferences: "Preferences",
        summary: "Auto-summary"
      },
      form: {
        name: "Project Name",
        description: "Project Description",
        simple: "Simple",
        predefinedKeywords: "Predefined Keywords",
        customKeywords: "Custom Keywords",
        advanced: "Advanced",
        dataSources: "Data Sources",
        keywordsArgs: "Keywords / Topics",
        articlePublicationDate: "Article Publication Date",
        deliverySchedule: "Delivery Schedule",
        daily: "Daily",
        weekly: "Weekly",
        monthly: "Monthly",
        additionalInformation: "Additional information"
      },
      messages: {
        notificationEmail: "You will receive a notification email on each delivery date.",
        areasOfInterestDisclaimer: "Sorcero will generate custom ontologies and search strings based on the keywords shared. This will take 2-3 days",
        additionalInformation: "Please feel free to send any additional information or project requirements to the Sorcero team via email at "
      }
    },
    buttons: {
      addProject: "Add Project",
      viewReport: "View Report"
    }
  },
  login: {
    labels: {
      forgotPassword: "Forgot password?",
      doNotHaveAccount: "Don't have an account?",
      signUp: "Sign up",
      welcome: "Welcome !",
      labelHook: "Analyze Scientific Content at Scale",
      errorLogin: "Incorrect email or password",
      userAccount: {
        title: "Set up your user account",
        description: "You can add more team members after your account has been created."
      }
    },
    inputs: {
      email: "Email",
      password: "Password",
      workEmail: "Work email address *",
      setPassword: "Set a password *"
    },
    buttons: {
      login: "Login"
    }
  },
  createInstance: {
    labels: {
      about: {
        title: "About your company",
        description: "To get started, please tell us your company name so what we can create a dedicated workspace for your team members to create projects."
      },
      banner: {
        description: "Create your company account in less than 5 minutes",
        companyDetails: "Company details",
        userDetails: "User details",
        final: "You are all set!"
      },
      account: {
        createdMessage: "Your account has been created.",
        instructionsMessage: "We have emailed you a verification link. Unverified accounts are deactivated 48 hours after creation.",
        verifyMessage: "Please verify your email address for continued access."
      },
      companyName: "Company Name *",
      companyLink: "Your company instance link",
      companyLogo: "Company Logo",
      companyLogoMessage: "Personalise the app with your company branding.",
      passwordRequirements: "Min 8 characters,  alphanumeric"
    },
  },
  landingPage: {
    labels: {
      startTitle: "Scientific and Medical Literature remains an important source of information to identify suspected adverse reactions.",
      startSubtitle: "Sorcero helps you to reduce the effort in literature monitoring while minimising the risk of missing references. We use ML to determine the relevance of a reference, giving a ranking of between 1 and 0 to each article.",
      literatureSurveillance: "Literature Surveillance",
      literatureSurveillanceDescription: "A periodic scanning ot literature to detect when guideline recommendations may require change. There are 2 times in the standard process that literature surveillance is often performed: During guideline development and between versions of guidelines. In each case, the process for how to manage the literature is similar."
    },
    buttons: {
      startTrial: "Start your Trial"
    }
  },
  ilm: {
    labels: {
      starredArticlesOnly: "Starred articles only",
      articles: "Articles",
      articleLinks: "Article Link/s",
      showLess: "show less",
      more: "more",
      filters: "Filters",
      source: "Source",
      articleType: "Article Type",
      journal: "Journal",
      author: "Author",
      tag: "Tag",
      backToSearchPage: "Back to Search Page",
      excerpt: "Excerpt",
      placeholderSource: 'Select source/s',
      placeholderJournal: 'Select journal/s',
      placeholderAuthor: 'Select author/s',
      placeholderArticleType: 'Select article type/s',
      placeholderTag: 'Select tag/s',
      filtersApplied: "Filters Applied",
      noFiltersApplied: "No Filters Applied",
      copyLink: "Copy Link",
      datePublished: "Date Published"
    },
    buttons: {
      copyCitation: "Copy Citation"
    }
  }
}