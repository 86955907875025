export default [{
  path: '/projects',
  alias:'/',
  name: 'MyProjects',
  meta :{
    requiresAuth:true
  },
  component: () => import('../views/MyProjects.vue')
}, {
  path: '/project/creation',
  name: 'NewProject',
  meta :{
    requiresAuth:true
  },
  component: () => import('../views/NewProject.vue')
}]