export default [{
  path: '/ilm/:projectId',
  name: 'IlmProject',
  meta :{
    requiresAuth: true
  },
  component: () => import('../views/Project.vue')
}, {
  path: '/ilm/export/:projectId',
  name: 'ExportIlmProject',
  meta :{
    requiresAuth: true,
    showNavbar: false
  },
  component: () => import('../views/ExportArticleList.vue')
}, {
  path: '/ilm/:projectId/:articleId',
  name: 'IlmArticle',
  meta :{
    requiresAuth: true
  },
  component: () => import('../views/Article.vue')
}]