import Vue from 'vue'
import VueRouter from 'vue-router'

import ProjectsRoutes from '@/modules/projects/router'
import IntelligentLiteratureMonitoring from '@/modules/intelligentLiteratureMonitoring/router'
import LoginRoutes from '@/modules/login/router'
import {authGuard} from "@/modules/auth/AuthGuard";

Vue.use(VueRouter)

const baseRoutes = [
  {
    path: '/9d9f418f-b35c-4732-996b-402a296b101f-c8cc/',
    alias: '/landing',
    name: 'Home',
    meta :{
      requiresAuth:false
    },
    component: () => import('../modules/common/views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta :{
      requiresAuth:false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const routes = baseRoutes.concat(ProjectsRoutes, IntelligentLiteratureMonitoring, LoginRoutes)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(authGuard)

export default router
