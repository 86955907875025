import { es } from 'vuetify/lib/locale'

export default {
  ...es,

  common: {
    labels: {
      optional: "Opcional",
      accept: "Aceptar",
      termsAndConditions: "Términos y condiciones",
      project: "Proyecto",
      thankYou: "Gracias!",
      selectAll: "Seleccionar todo"
    },
    buttons: {
      upload: "Cargar",
      create: "Crear",
      next: "Siguiente",
      finish: "Terminar",
      previousStep: "Paso anterior",
      getStarted: "Empezar",
      apply: "Aplicar",
      clearAll: "Limpiar todo",
      expandAll: "Expandir todo",
      collapseAll: "Colapsar todo",
      clearAllStars: "Limpiar favoritos"
    }
  },
  projects: {
    labels: {
      myProject: "Mis Proyectos",
      newProject: "Nuevo proyecto",
      intelligentLiteratureMonitoring: "Monitorización inteligente de la literatura",
      banner: {
        content: "Contenido",
        areasOfInterest: "Áreas de Interés",
        preferences: "Preferencias",
        summary: "Auto-Resumen"
      },
      form: {
        name: "Nombre",
        description: "Descripción",
        simple: "Simple",
        predefinedKeywords: "Palabras clave predefinidas",
        customKeywords: "Palabras clave personalizadas",
        advanced: "Avanzado",
        dataSources: "Fuentes de datos",
        keywordsArgs: "Palabras clave / Tópicos",
        articlePublicationDate: "Fecha de Publicación del Artículo",
        deliverySchedule: "Programa de entrega",
        daily: "Diariamente",
        weekly: "Semanal",
        monthly: "mensual",
        additionalInformation: "Información adicional"
      },
      messages: {
        notificationEmail: "Recibirás una notificación por correo electrónico en cada fecha de entrega",
        areasOfInterestDisclaimer: "Sorcero generará ontologías y criterios de búsqueda basados en las áreas de interés compartidas. Esto tomará 2-3 días",
        additionalInformation: "Siéntase libre de enviar cualquier informacion adicional o requrimientos para el proyecto al email del equipo de Sorcero en "
      }
    },
    buttons: {
      addProject: "Agregar Proyecto",
      viewReport: "Ver Reporte"
    }
  },
  login: {
    labels: {
      forgotPassword: "¿Olvidaste tu contraseña?",
      doNotHaveAccount: "¿No tienes una cuenta?",
      signUp: "Registrarse",
      welcome: "Bienvenido !",
      labelHook: "Construyendo Aplicaciones de Lenguaje Inteligente para un cambio en la disposición de información",
      errorLogin: "Correo electrónico o contraseña incorrectos",
      userAccount: {
        title: "Configura tu cuenta de usuario",
        description: "Puede agregar más miembros al equipo después de que se haya creado su cuenta."
      }
    },
    inputs: {
      email: "Correo electrónico",
      password: "Contraseña",
      workEmail: "Dirección de correo electrónico del trabajo *",
      setPassword: "Establecer una contraseña *"
    },
    buttons: {
      login: "Iniciar sesión"
    }
  },
  createInstance: {
    labels: {
      about: {
        title: "Acerca de su empresa",
        description: "Para comenzar, díganos el nombre de su empresa para que podamos crear un espacio de trabajo dedicado para que los miembros de su equipo creen proyectos."
      },
      banner: {
        description: "Cree el espacio de trabajo de su empresa en menos de 5 minutos",
        companyDetails: "Detalles de la empresa",
        userDetails: "Detalles del usuario",
        final: "¡Ya está todo listo!"
      },
      account: {
        createdMessage: "Su cuenta ha sido creada",
        instructionsMessage: "Le hemos enviado un enlace de verificación por correo electrónico. Las cuentas no verificadas se desactivan 48 horas después de su creación.",
        verifyMessage: "Verifique su dirección de correo electrónico para tener acceso continuo."
      },
      companyName: "Nombre de la empresa *",
      companyLink: "Enlace de instancia de su empresa",
      companyLogo: "Logotipo de la empresa",
      companyLogoMessage: "Personalice la aplicación con la marca de su empresa",
      passwordRequirements: "Mínimo 8 caracteres, alfanumérico"
    }
  },
  landingPage: {
    labels: {
      startTitle: "La literatura científica y médica sigue siendo una fuente importante de información para identificar sospechas de reacciones adversas",
      startSubtitle: "Sorcero le ayuda a reducir el esfuerzo en el seguimiento de la literatura al tiempo que minimiza el riesgo de que falten referencias. Usamos ML para determinar la relevancia de una referencia, dando una clasificación de entre 1 y 0 a cada artículo.",
      literatureSurveillance: "Literature Surveillance",
      literatureSurveillanceDescription: "Un escaneo periódico de la literatura para detectar cuándo las recomendaciones de las guías pueden requerir cambios. Hay dos momentos en el proceso estándar en los que a menudo se realiza la vigilancia de la literatura: durante el desarrollo de la guía y entre versiones de la guía. En cada caso, el proceso de gestión de la literatura es similar."
    },
    buttons: {
      startTrial: "Inicie su prueba"
    }
  },
  ilm: {
    labels: {
      starredArticlesOnly: "Solo artículos destacados",
      articles: "Artículos",
      articleLinks: "Enlaces de artículos",
      showLess: "mostrar menos",
      more: "más",
      filters: "Filtros",
      source: "Fuente",
      articleType: "Tipo de artículo",
      journal: "Diario",
      author: "Autor",
      tag: "Etiqueta",
      backToSearchPage: "Volver a la página de búsqueda",
      excerpt: "Extracto",
      placeholderSource: 'Seleccionar fuente/s',
      placeholderJournal: 'Seleccionar diario/s',
      placeholderAuthor: 'Seleccionar autor/es',
      placeholderArticleType: 'Seleccionar tipo/s de artículo',
      placeholderTag: 'Seleccionar etiqueta/s',
      filtersApplied: "Filtros Aplicados",
      noFiltersApplied: "Sin Filtros Aplicados",
      datePublished: "Fecha de publicación",
      copyLink: "Copiar Enlace"
    },
    buttons: {
      copyCitation: "Copiar Cita"
    }
  }
}