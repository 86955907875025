import Vue from 'vue'
import Vuex from 'vuex'
import AuthStore from "@/modules/auth/store/AuthStore";

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    AuthStore
  },
  strict: debug
})

