import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import en from '@/locales/en'
import es from '@/locales/es'

var theme = require(`../themes/${ process.env.VUE_APP_THEME }.js`)

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en, es },
    current: 'en',
  },
  theme: {
    light: true,
    themes: {
      light: theme.default
    },
  }
});
