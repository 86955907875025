export default [{
  path: '/login',
  name: 'Login',
  meta: {
    title: 'Login',
    requiresAuth:false,
    showNavbar: false
  },
  component: () => import('../views/Login.vue')
}, {
  path: '/create-instance',
  name: 'CreateInstance',
  meta: {
    title: 'CreateInstance',
    requiresAuth: false,
    showNavbar: false
  },
  component: () => import('../views/CreateInstance.vue')
}]