<template>
  <v-app-bar class="navbar" elevation="0" app>
    <v-toolbar-title>
      <v-img src="@/assets/images/logo_sorcero.svg" @click="goHome" class="pointer"/>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <div v-if="isAuthenticated()">

      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mx-3" small icon v-bind="attrs" v-on="on">
            <v-img src="@/assets/images/icon_navbar_profile.svg"/>
          </v-btn>
        </template>

        <v-list>
          <v-list-item  @click="logOut">
            <v-list-item-title>Log Out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div v-else>
      <v-btn class="openSans-300" text color="primary" to="/login">{{ $vuetify.lang.t('$vuetify.login.buttons.login') }}</v-btn>
    </div>

  </v-app-bar>
</template>

<script>
import store from "@/store"
import {LOGOUT} from "@/modules/auth/store/actions/AuthActions"
export default {

  name: 'NavBar',

  data () {
    return {

    }
  },
  methods: {
    goHome() {
      if (this.$route.name == 'MyProjects') {
        this.$router.go()
      } else {
        this.$router.push('/')
      }
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    },
    logOut() {
      console.log('logout')
      store.dispatch(LOGOUT)
    }
  }
}
</script>

<style lang="scss">
</style>