import {LOGIN} from "@/modules/auth/store/actions/AuthActions"
import {LOGOUT} from "@/modules/auth/store/actions/AuthActions";
import router from "@/router";

const state = {
  token: localStorage.getItem('user-token') || null,
}
const getters = {
  isAuthenticated: state => !!state.token,
  getToken: state => state.token
}
const actions = {
  [LOGIN] : ({commit}, token) => {
    localStorage.setItem('user-token', token)
    commit('tokenSetSuccess', token)
  },
  [LOGOUT] : ({commit}) => {
    localStorage.removeItem('user-token')
    commit('tokenRemovalSuccess')
    router.push("/")
  }
}

const mutations = {
  tokenSetSuccess(state, token){
    state.token=token
  },
  tokenRemovalSuccess(state){
    state.token=null
  },
}

export default {
  state, mutations, actions, getters
}
